@import "./utils/bootstrap-variables";
@import "./bootstrap";

@import url('https://fonts.googleapis.com/css2?family=Merriweather&family=Raleway:wght@900&display=swap');

$logo-zindex: 10;

.nav-container {
  z-index: 0;
  position: relative;
  height: 250px;

  @include media-breakpoint-up(md) {
    position: static;
    height: inherit;
  }
}

.nav {
  position: fixed;
  z-index: $logo-zindex;
  background: white;
  width: 100%;
  height: 250px;

  @include media-breakpoint-up(md) {
    height: auto;
    position: static;
    background: black;
  }
}

.logo {
  background: #fff;
  position: relative;
  margin: 0 auto 0;
  z-index: $logo-zindex;
  width: 250px;
  height: 250px;
  border-radius: 100%;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  display: flex;
  border: 0 solid white;
  transition: .3s ease-out border, .3s ease-out width, .3s ease-out height;

  @include media-breakpoint-up(md) {
    top: 3rem;
    right: 3rem;
    z-index: 10;
    width: 250px;
    height: 250px;
    position: fixed;
  }

  @include media-breakpoint-up(lg) {
    width: 300px;
    height: 300px;
  }

  &:hover {
    @include media-breakpoint-up(md) {
      border: 15px solid white;

    }
  }

  &.is-small {
    @include media-breakpoint-up(md) {
      width: 80px;
      height: 80px;
    }
  }

  img {
    width: 85%;
  }
}

.header {
  background: black;

  &__slide {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    @include media-breakpoint-up(md) {
      min-height: 100vh;
    }
  }
}

.credits {
  display: flex;
  align-items: center;
  overflow: hidden;
  background-image: url('../assets/night.jpeg');
  background-size: cover;
  text-align: center;
  color: #fff;


  @include media-breakpoint-up(md) {
    height: 100vh;
  }


  @include media-breakpoint-up(lg) {
    background-attachment: fixed;
  }

  &__content {
    @include media-breakpoint-up(md) {
      margin: -6rem auto 0;
      max-width: 600px;
    }

  }
}


.view {
  position: relative;
  padding: 5rem 0;

  @include media-breakpoint-up(md) {
    padding: 0;
    height: 100vh;
    min-height: 700px;
  }
}

.who {
  position: relative;
  color: #fff;
  background-color: black;
  background-image: url('../assets/bsc.jpeg');
  background-position: top;
  background-size: cover;
  background-position: center;
  text-align: center;
  padding: 5rem 0;



  &__underlay {
    background: black;
  }

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .6);
  }
}

h1 {
  font-size: 3.5rem;
}

.facebook-icon {
  width: 90px;
  height: auto;
  filter: invert(1);
}


.text-yellow {
  color: $yellow;
}

.footer {
  opacity: .4;
}

.social-link {
  opacity: .5;
  transition: .255s ease-out opacity;

  &:hover {
    opacity: 1;

  }
}



.docu {
	position: relative;
	background: #000
}

.docu__content {
	max-width: 1200px;
  margin: 0 auto;

  @include media-breakpoint-up(md) {
    padding: 4rem 0
  }
}